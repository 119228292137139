<template>
  <div class="user">
    <div class="form_info">
      <el-form ref="form" :model="queryInfo" label-width="80px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="所在地区:" prop="">
              <el-select v-model="queryInfo.province" placeholder="请选择省份" @change="citychange">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select v-model="queryInfo.city" placeholder="请选择地区" @change="search">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业类型:" prop="">
              <el-radio-group v-model="queryInfo.customerType" @change="search">
                <el-radio-button label="" :value="''">全部</el-radio-button>
                <el-radio-button
                  :label="item.id"
                  v-for="(item, index) in InformationTypes"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label=" " label-width="0px" style="padding-top: 20px; margin-bottom: 0">
              <div class="input-with">
                <div style="display: flex; align-items: center">
                  <el-button plain @click="exportExcel">批量导出</el-button>
                  <div style="position: relative; top: 10px; left: 10px">
                    <el-checkbox
                      :indeterminate="isIndeterminate"
                      v-model="checkAll"
                      @change="handleCheckAllChange"
                      >全选</el-checkbox
                    >
                  </div>
                </div>
                <div>
                  <el-input
                    placeholder="请输入企业名称"
                    v-model="queryInfo.queryConditions"
                    class="input-with-select"
                  >
                    <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div
      class="tab_txt"
      v-loading="loading"
      element-loading-text="准备中"
      element-loading-spinner="el-icon-loading"
    >
      <span style="position: relative; top: -10px">当前条件下共查出{{ total }}家企业</span>
      <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
        <div class="item_checkbox">
          <el-checkbox
            @change="latechange($event, item)"
            v-model="item.checked1"
            label=""
          ></el-checkbox>
        </div>
        <div class="storeCenter_item_top">
          <div class="storeCenter_item_top_left">
            <img v-if="item.companyLogo" :src="item.companyLogo" alt="" />
            <img v-else src="../../../assets/image/Group 3237.png" alt="" />
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <h5 style="display: inline-block; margin: 0; font-size: 18px">
                  {{ item.companyFullName }}
                </h5>
              </div>
              <div class="storeCenter_item_top_left_flex_tag">
                <span v-for="(o, index) in item.labelList" :key="index">
                  <span class="office">{{ o }}</span>
                </span>
                <el-popover placement="right" width="230" trigger="click">
                  <div
                    class="hidden_label_box"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-content: center;
                      flex-wrap: wrap;
                    "
                  >
                    <span
                      class="office"
                      style="
                        border: 1px solid #559eff;
                        color: #4e93fb;
                        box-sizing: border-box;
                        padding: 1px 6px;
                        display: block;
                        font-size: 14px;
                        font-weight: 400;
                        border-radius: 4px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                      "
                      v-for="(o, index) in item.labelList && item.labelList.length
                        ? item.labelList.filter((el, index) => index > 3)
                        : []"
                      :key="index"
                      >{{ o }}</span
                    >
                  </div>
                  <span slot="reference" v-if="item.labelList && item.labelList.length > 4">{{
                    item.labelList.length - 4
                  }}</span>
                </el-popover>
              </div>
              <p class="address">
                <span style="padding-left: 0px"> {{ item.legalPerson }}</span>
                <span> {{ item.companyPhone }}</span>
                <span> {{ item.province }}-{{ item.city }}</span>
                <span>
                  <a style="color: #4e93fb" href="">{{ item.officialWebsite }}</a>
                </span>
              </p>
            </div>
          </div>
          <div class="storeCenter_item_top_right">
            <div class="account_cycle" @click="beforeAccountDialog(item.id)">
              <img
                src="../../../assets/image/Group-1.png"
                alt=""
                style="width: 16px; height: 16px; margin-right: 10px; vertical-align: top"
              />
              账号周期：{{ item.beginTime }}至{{ item.deadline }}
              <span v-if="item.prompt" style="color: #fd702b; width: 80px; display: inline-block">
                <img
                  src="../../../assets/image/Group.png"
                  alt=""
                  style="width: 16px; height: 16px; margin-right: 10px; vertical-align: top"
                />即将到期</span
              >
              <span v-else style="width: 80px; display: inline-block"></span>
            </div>

            <div class="operation_area">
              <el-button type="primary" @click="toPage(item)">基本信息</el-button>
              <el-button type="info" @click="clciklabel(item)" plain>关联订单</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="new_page" v-if="listData.length">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>

      <el-dialog
        title="账号周期"
        :visible.sync="accountDialogVisible"
        width="527px"
        class="account_dialog"
        :before-close="afteraccountDialogHandle"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="账号周期" prop="date">
            <el-date-picker
              value-format="yyyy-MM-DD"
              v-model="ruleForm.date"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              required
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>

        <div style="text-align: center; margin-top: 40px">
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          <el-button @click="afteraccountDialogHandle">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { updateCompanyAccountCycle } from '@/api/datement'
import { partnerCompanyPage, exportExcel, selectPartnerById } from '@/api/channel'
import { getByFatherIdt, getProvince } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  customerType: '',
  industry: null,
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  queryConditions: null
})
export default {
  name: 'relevance',
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      ruleForm: {
        date: null
      },
      checkAll: false,
      isIndeterminate: false,
      InformationTypes: [
        {
          value: '智参企业',
          id: '1'
        },
        {
          value: '智选企业',
          id: '2'
        }
      ],
      rules: {
        date: [{ required: true, message: '请选择账号周期', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },

      accountDialogVisible: false,
      currentId: null,
      labelList: [],
      ids: [],
      idsList: [],
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      operatingList: [], //省
      operatingLists: [], //市
      pattnerinfo: {}
    }
  },
  created() {
    this.search()
    this.incity()
    this.selectPartnerById()
  },
  methods: {
    //合作伙伴信息
    async selectPartnerById() {
      const res = await selectPartnerById({ id: this.id })
      if (res.data.resultCode == 200) {
        this.pattnerinfo = res.data.data
      }
    },
    //企业基本信息
    toPage(row) {
      this.$router.push({
        path: '/customer/addenterprise',
        query: {
          id: row.id,
          headTabActive: 1
        }
      })
    },
    //关联订单
    clciklabel(row) {
      //     this.$router.push({
      //         path: '/channel/associatedorder',
      //     query: {
      //       id: row.id,
      //     }
      //   })
    },
    afteraccountDialogHandle() {
      this.ruleForm.date = null
      this.accountDialogVisible = false
    },
    submitForm(formName) {
      console.log(this.ruleForm.date, 'dete')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateCompanyAccountCycle({
            beginTime: this.ruleForm.date[0],
            deadline: this.ruleForm.date[1],
            id: this.currentId
          }).then(({ data: res }) => {
            if (res.resultCode === 200) {
              this.afteraccountDialogHandle()
              this.search()
            } else {
              this.message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    beforeAccountDialog(id) {
      this.currentId = id
      this.accountDialogVisible = true
    },

    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
            console.log(this.operatingLists, 'this.operatingLists ')
          } else {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }

          console.log(item, 'item', this.operatingList)
          // this.getByFatherIdt(o.code)
        }
      })
      this.search()
    },
    async search() {
      this.loading = true
      this.queryInfo.id = this.id
      const res = await partnerCompanyPage(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
        this.listData.forEach((item) => {
          if (item.labelList != null) {
            item.labelList = item.labelList.slice(0, 3)
          }
          this.$set(item, 'checked1', false)
        })
      }
    },
    //批量导出
    async exportExcel() {
      this.loading = true
      const query = {
        id: this.id,
        ids: this.ids
      }
      const res = await exportExcel(query)
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `${this.pattnerinfo.partnerName}企业列表${nowtime}.xlsx` //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        this.search()
        this.isIndeterminate = false
        this.checkAll = false
      } else {
        this.$message.error('下载失败!')
      }
    },
    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
    },
    handleCheckAllChange(val) {
      if (val) {
        this.listData.forEach((item) => {
          this.ids.push(item.id)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.listData.forEach((item) => {
          this.ids = []
          this.idsLis = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

.enterprise_dialog {
  .tip {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }
  .enterprise_box {
    .enterprise_item {
      margin-top: 10px;
      width: 387px;
      padding: 10px;
      background: #f4f4f4;
      border-radius: 1px;
      display: flex;
      justify-content: space-between;
      .left {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        /* identical to box height */

        color: #151515;
      }
      .right {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        color: #fd5469;
      }
    }
  }
}
.user {
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    margin-top: 20px;
    padding: 0px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;

        img {
          width: 90px;
          height: 90px;
          border-radius: 4px;
          margin-right: 14px;
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;
            padding-top: 13px;

            span {
              box-sizing: border-box;
              padding: 1px 6px;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              border: 1px solid #559eff;
              color: #4e93fb;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }

          .address {
            font-size: 14px;
            span {
              padding: 0px 10px;
            }
          }
        }
      }
      .storeCenter_item_top_right {
        align-items: stretch;
        height: 100%;
        .account_cycle {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          /* 灰色字体 */

          color: #969495;
        }
        .operation_area {
          margin-top: 30px;
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .edit {
            padding-left: 20px;
            color: #4e93fb;
            cursor: pointer;
          }
          .frozen {
            padding-left: 20px;
            color: #fd5469;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
